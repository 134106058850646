import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Layout from '../components/Layout'
import Header from '../components/Header'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges

    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout
        header={ <Header title="Tags" /> }
        content={
          <section className="section">
            <Helmet title={`${tag} | ${title}`} />
            <div className="container content">
              <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
              <List component="nav">
                { posts.map(post => (
                  <ListItem key={post.node.fields.slug} button component={Link} to={post.node.fields.slug}>
                    <ListItemText primary={post.node.frontmatter.title} />
                  </ListItem>
                ))}
              </List>
              <p>
                <Link to="/tags/">Browse all tags</Link>
              </p>
            </div>
          </section>
        }
      />
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
